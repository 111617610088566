import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Playlist from '../../redux/playlists/types';
import { LibraryComponents } from '../../redux/library/types';
import { getItemName } from '../../utils/helpers';
import { ReactComponent as ArrowRightSVG } from '../../images/2023/svg/small/dropdown/arrow_right_6_10.svg';
import { actionShowMessage } from '../../redux/support/action';
import {
  Group,
  ITEM,
  MessageType,
  openModalType,
  PLAYLIST_TYPES_REVERT,
  SHARED_PLAYLIST_URL,
  stopPropagation,
} from '../../utils/constants';
import { axiosAbortarium } from '../../utils/axiosAbortarium';
import { actionOpenAIModal, actionOpenModal } from '../../redux/user/action';
import { ServiceUser } from '../../redux/user/types';
import { CHANNEL_PERMISSIONS, isRoleInPlaylist } from '../../utils/permissions';
import { Channels } from '../../redux/channels/types';
import {
  Divider,
  OptionsWrapper,
} from '../../components/MiniPlaylist/NewPlaylist2023/styled';
import { THREE_DOTS_DD_OPTIONS_UPV_TABLE } from '../../components/MiniPlaylist/NewPlaylist2023/helpers/enums';
import PlaylistSelector from './MakerCommon/Table/PlaylistSelector';
import { actionCreator } from '../../shared/redux/actionHelper';
import {
  actionRemoveLinkPageFromPlaylist,
} from '../../redux/currentPage/action';
import { ThreeDotsDropWrapperMaker } from './styled';
import { actionUpdateReadState } from '../../redux/playlists/action';
import { CurrentPage } from '../../redux/currentPage/types';

// separated to keep codebase cleaner then now. is used in 5 places for new 2023
// maker - list view, card view, table of content, whole playlist controls
// recipient - ThreeDotsDropDownUPV
const ThreeDotsDropDownMaker = ({
  isMaker,
  show,
  currentPage,
  refMenu,
  isWholePlaylistOperation,
  isTOC,
  positionDown,
  upvCard,
  currentRole,
  isEmpty,
  isPlaceholder,
  upvExpand,
  onRename = null,
  left,
  top,
  ...props
}) => {
  const { isViewer: exViewer, isOwner, isCoEdit: exCoEdit } = isRoleInPlaylist;
  const [showPlaylistSelector, setShowPlaylistSelector] = useState(false);
  const {
    type: typePlaylist,
    place = 'draft',
    channelID = null,
    id: sharedToWebPlaylistID,
  } = useParams();
  const { t } = useTranslation();
  const channels = useSelector((state) => state.channels.myChannels);
  const dispatch = useDispatch();
  const history = useHistory();
  const isShared = history.location.pathname.includes('shared_playlist');
  const isForSharedToWeb = history.location.pathname.includes(SHARED_PLAYLIST_URL);
  const userID = useSelector((state) => state.user.id);
  const isPlaylistOwner = userID === currentPage?.owner?.id;
  const isPublished = currentPage?.status === 'publish';
  const isCoEdit = exCoEdit[currentRole];
  const isViewer = exViewer[currentRole];
  const itemOwner = userID === props?.item?.owner?.id;
  const isLocked = props?.item?.isRemixLocked;
  const isCanEdit = isCoEdit || itemOwner || isPlaylistOwner;
  const isCanRemix = itemOwner || !isLocked;
  const canUnsubscribe = typePlaylist === 'shared' && isWholePlaylistOperation;
  const canRemixMany = !!currentPage?.linkPages?.length && isWholePlaylistOperation;

  const isCanBeAddToLibrary = !props.item?.textComponent?.id;
  const isCanAddToLibrary = !isEmpty && (canRemixMany
    || (isCanRemix && isCanBeAddToLibrary)
    || (!isLocked && isCanBeAddToLibrary)
    || (isCanBeAddToLibrary && itemOwner));
  const isCanDelete = !isViewer && !isWholePlaylistOperation || isOwner[currentRole];

  const roleInChannel = channels[channelID]?.role;
  const isChannelAdmin = roleInChannel === 'admin';
  const isChannelCoAdmin = roleInChannel === 'co_admin';
  const roleOwnerPlaylist = currentPage?.owner?.roleInChannel;

  const isHasMoreRoleFromOwnerPlaylist = CHANNEL_PERMISSIONS[roleInChannel]?.whichContentCanRemove[
    roleOwnerPlaylist
  ];
  const isCanRemoveFromChannel = channelID
    && isWholePlaylistOperation
    && (isPlaylistOwner || isChannelAdmin || isHasMoreRoleFromOwnerPlaylist);
  const isShowNotActiveRemoveFromChannel = channelID
    && isWholePlaylistOperation
    && !isCanRemoveFromChannel
    && isChannelCoAdmin;

  const libraryComponentId = props.item?.libraryComponent?.id
    || props.item?.textComponent?.id
    || props.item?.id;
  const libraryComponentType = props.item?.libraryComponent?.type || props.item?.type;
  const libraryComponentTitle = props.item?.title || props.item?.libraryComponent?.title;

  const allowedItems = useMemo(() => {
    return currentPage.linkPages?.reduce((acc, cur) => {
      if (currentPage.selectedItems[cur.id]
        && (!cur.isRemixLocked || cur.owner?.id === userID)
      ) {
        acc.forSF.push(cur.id);
        if (!cur.textComponent?.id) {
          acc.forLibrary.push(cur.id);
        }
      }
      return acc;
    }, { forLibrary: [], forSF: [] });
  }, [currentPage.linkPages, currentPage.selectedItems, userID]);

  const onAddItemsToLibraryClickHandler = (e) => {
    e.stopPropagation();
    if (isWholePlaylistOperation) {
      dispatch(actionCreator(Playlist.AddAllDownloadableItemsIntoLibrary));
      props.setIsActive(false);
      return;
    }
    if (props.isMultiActionPanel) {
      if (allowedItems.forLibrary.length) {
        dispatch(actionCreator(Playlist.AddAllDownloadableItemsIntoLibrary, { items: allowedItems.forLibrary }));
      }
      props.setIsActive(false);
      return;
    }
    if (
      props.item.type !== 'elementComponent'
      && props.item?.libraryComponent?.type !== 'page'
    ) return;
    props.setIsActive(false);
    dispatch(
      actionCreator(LibraryComponents.Player.DuplicateSharedComponent, {
        componentId: props.item?.libraryComponent?.id,
        linkPageId: props.item?.id,
        type: props.item?.libraryComponent?.type,
      }),
    );
    props.setIsActive(false);
  };

  const goToLibComponent = (e) => {
    e.stopPropagation();
    props.setIsActive(false);
    if (props.goToLibComponent) {
      props.goToLibComponent();
    } else {
      const elemPosition = props.itemRef?.current?.getBoundingClientRect();
      dispatch(
        actionOpenModal(openModalType.UPVPreview, { id: currentPage.id, index: props.itemIndex, mode: 'UPV', elemPosition }),
      );
    }

    if (isForSharedToWeb) return;
    const currentLinkPage = currentPage?.linkPages[props.itemIndex];
    if (!currentLinkPage.isViewed && !isOwner[currentRole]) {
      dispatch(actionUpdateReadState(currentLinkPage?.mainUserInterface?.id, currentLinkPage.isViewed, currentLinkPage.id));
    }
  };

  const deleteLinkPageClickHandler = (e) => {
    e.stopPropagation();
    if (isWholePlaylistOperation) {
      if (isPublished) {
        return;
      }
      props.setIsActive(false);
      dispatch(
        actionOpenModal(openModalType.ConfirmModalUniversal, {
          title: t('deleteUpT'),
          subject: t('deleteThisSmartFileQuestionT'),
          description: t('deleteDescriptionT'),
          confirm: () => {
            dispatch(
              actionCreator(Playlist.updateMoveToTrash, {
                wrapperId: currentPage.wrapperId,
                state: true,
                id: currentPage.id,
                moveCallback: () => {
                  dispatch(
                    actionShowMessage({
                      type: MessageType.Regular,
                      text: t('smartFileDeletedT'),
                    }),
                  );
                  history.push(
                    `/content/smartfiles/${PLAYLIST_TYPES_REVERT[place]}`,
                  );
                },
              }),
            );
          },
          cancelText: t('cancelUpT'),
          okText: t('deleteUpT'),
        }),
      );
      return;
    }
    dispatch(
      actionRemoveLinkPageFromPlaylist(
        props.socketId || props.playlistId,
        props.item.id,
        props.item.textComponent?.id || props.item.libraryComponent?.id,
      ),
    );
    if (props.setIsActive) {
      props.setIsActive(false);
    }
    if (props.goNext) {
      props.goNext();
    }
  };

  const unsubscribeHandler = (e) => {
    const itemType = 'playlist';
    const historyType = 'smartfile';
    e.stopPropagation();
    props.setIsActive(false);
    dispatch(
      actionOpenModal(openModalType.ConfirmModalUniversal, {
        title: t('removeUpT'),
        subject: t('removeThisSmartFileQuestionT'),
        description: `${t('removeFromFeedModalDesc1T')}${
          isCoEdit ? t('removeFromFeedModalDesc2T') : ''
        }`,
        confirm: () => {
          dispatch(
            actionShowMessage({
              type: MessageType.Regular,
              text: t('smartFileRemovedT'),
            }),
          );
          dispatch(
            actionCreator(ServiceUser.unsubscribe, {
              id: currentPage.id,
              history,
              type: itemType,
              historyType,
            }),
          );
        },
        cancelText: t('cancelUpT'),
        okText: t('removeUpT'),
      }),
    );
  };

  // const summarizedHandler = (e) => {
  //   e.stopPropagation();
  //   if (props.setIsActive) {
  //     props.setIsActive(false);
  //   }
  //   const linkPages = () => {
  //     if (props.item) {
  //       return [props.item.id];
  //     }
  //     if (isWholePlaylistOperation) {
  //       const ids = currentPage.linkPages.map(elem => elem.id);
  //       return ids;
  //     }
  //     return Object.keys(currentPage.selectedItems);
  //   };
  //   dispatch(
  //     actionCreator(CurrentPage.Summarize, {
  //       linkPages: linkPages(),
  //     }),
  //   );
  //   dispatch(actionOpenModal(openModalType.AIUniversal, {
  //     item: props.item,
  //     aiType: 'ai_summarize_all',
  //   }));
  // };

  const AITaskHandler = (e) => {
    e.stopPropagation();
    if (props.setIsActive) {
      props.setIsActive(false);
    }
    const items = () => {
      if (props.item) {
        return [props.item];
      }
      if (isWholePlaylistOperation) {
        return currentPage.linkPages;
      }
      return currentPage.linkPages.filter(elem => currentPage.selectedItems[elem.id]);
    };
    dispatch(actionOpenAIModal(openModalType.AIUniversal, {
      items: items(),
      aiType: 'ai_send',
    }));
  };

  const removeFromChannel = (e) => {
    e.stopPropagation();
    if (isShowNotActiveRemoveFromChannel) return;
    props.setIsActive(false);
    dispatch(
      actionOpenModal(openModalType.ConfirmModalUniversal, {
        title: t('removeUpT'),
        subject: t('removeThisSmartFileQuestionT'),
        description: t('removeDescription1T'),
        confirm: () => {
          dispatch(
            actionShowMessage({
              type: MessageType.Regular,
              text: t('smartFileRemovedT'),
            }),
          );
          dispatch(
            actionCreator(Channels.RemoveContentInSharedChannel, {
              contentId: currentPage.wrapperId,
              type: ITEM.Playlist,
              moveCallback: () => history.push(`/channel/${channelID}`),
            }),
          );
        },
        cancelText: t('cancelUpT'),
        okText: t('removeUpT'),
      }),
    );
  };

  const renameClickHandler = (e) => {
    e.stopPropagation();
    onRename && onRename(e);
    props.setIsActive(false);
  };

  const downloadItemClickHandler = useCallback((e, filesArray, isLinkAdded, smartfileLink) => {
    e.stopPropagation();
    if (isWholePlaylistOperation) {
      dispatch(
        actionShowMessage({
          type: MessageType.DownloadWholePlaylist,
          itemName: 'playlist',
          mode: Group.processing,
          id: currentPage.id,
        }),
      );
      axiosAbortarium.generateNew(currentPage.id);
      dispatch(
        actionCreator(Playlist.DownloadAllDownloadablePlaylistItems, {
          playlistId: currentPage.id,
          playlistTitle: currentPage.title,
          filesArray,
          isLinkAdded,
          smartfileLink,
        }),
      );
      props.setIsActive(false);
      return;
    }

    dispatch(
      actionShowMessage({
        type: MessageType.DownloadOneItem,
        itemName: 'playlist',
        mode: Group.processing,
        id: libraryComponentId,
      }),
    );
    axiosAbortarium.generateNew(libraryComponentId);
    dispatch(
      actionCreator(Playlist.DownloadOneElement, {
        elementId: libraryComponentId,
        elementTitle: libraryComponentTitle,
        elementType: libraryComponentType,
        isText: !!props.item.textComponent?.id,
        text: getItemName(props.item),
        owner: props.item.owner || { id: userID },
      }),
    );
    props.setIsActive(false);
  }, [currentPage.id, currentPage.title,
    isWholePlaylistOperation, libraryComponentId, libraryComponentTitle,
    libraryComponentType, props.item, userID]);

  const duplicateClickHandler = (e) => {
    e.stopPropagation();
    props.setIsActive(false);
    props.duplicateHandler && props.duplicateHandler();
  };

  useEffect(() => {}, [props.item?.id, props.item?.libraryComponent?.id]);

  const disabledBySelectedItemsForLibraryAdd = Object.keys(currentPage.selectedItems).length && !allowedItems.forLibrary.length;
  const disabledBySelectedItemsForSFAdd = Object.keys(currentPage.selectedItems).length && !allowedItems.forSF.length;

  const aiOn = localStorage.getItem('aiOn');
  if (!show) return <></>;

  if (isTOC) {
    return (
      <ThreeDotsDropWrapperMaker
        data-parent="miniPage"
        ref={refMenu}
        onClick={stopPropagation}
        isWholePlaylistOperation={isWholePlaylistOperation}
        isShort={!isCanEdit}
        positionDown={positionDown}
        isTOC
      >
        <OptionsWrapper isFirst>
          {t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.title)}
        </OptionsWrapper>
        {isCanDelete && (
          <OptionsWrapper
            data-parent="miniPage"
            disabled={
              (!itemOwner && isCoEdit)
              || (!itemOwner && isLocked)
              || isPlaceholder
              || isViewer
            }
            onClick={
              (!itemOwner && isCoEdit)
              || (!itemOwner && isLocked)
              || isPlaceholder
              || isViewer
                ? stopPropagation
                : duplicateClickHandler
            }
          >
            {t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.duplicate)}
          </OptionsWrapper>
        )}

        <OptionsWrapper
          data-parent="miniPage"
          disabled={!isCanRemix || isPlaceholder}
          onClick={
            !isCanRemix || isPlaceholder
              ? (e) => e.stopPropagation()
              : downloadItemClickHandler
          }
        >
          {t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.download)}
        </OptionsWrapper>
        {isCanDelete && (
          <>
            <Divider />
            <OptionsWrapper
              isLast
              disabled={
                isViewer
                || (!itemOwner && isCoEdit)
                || ((isCoEdit || isLocked) && !itemOwner && !isOwner)
              }
              onClick={
                isViewer
                || (!itemOwner && isCoEdit)
                || ((isCoEdit || isLocked) && !itemOwner && !isOwner)
                  ? stopPropagation
                  : deleteLinkPageClickHandler
              }
            >
              {!isWholePlaylistOperation
                && t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.delete)}
              {isWholePlaylistOperation
                && t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.deletePlaylist)}
            </OptionsWrapper>
          </>
        )}
      </ThreeDotsDropWrapperMaker>
    );
  }

  return (
    <ThreeDotsDropWrapperMaker
      isMaker={isMaker}
      data-parent="miniPage"
      ref={refMenu}
      onClick={stopPropagation}
      isWholePlaylistOperation={isWholePlaylistOperation}
      isShort={!isCanEdit}
      upvCard={upvCard}
      isCardView={props.isCardView}
      upvExpand={upvExpand}
      isTextCardView={props.isTextCardView}
      isMultiActionPanel={props.isMultiActionPanel}
      top={top}
      left={left}
    >
      <OptionsWrapper isFirst>
        {t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.title)}
      </OptionsWrapper>
      {!(isShared || isForSharedToWeb) && (
        <>

          <OptionsWrapper
            data-parent="miniPage"
            disabled={!isCanAddToLibrary || isEmpty || disabledBySelectedItemsForLibraryAdd}
            onClick={
              !isCanAddToLibrary || disabledBySelectedItemsForLibraryAdd
                ? stopPropagation
                : onAddItemsToLibraryClickHandler
            }
          >
            {t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.addToLibrary)}
          </OptionsWrapper>
          <OptionsWrapper
            data-parent="miniPage"
            disabled={!isCanRemix || isEmpty || disabledBySelectedItemsForSFAdd}
            onMouseEnter={
              (!isCanRemix || isEmpty || disabledBySelectedItemsForSFAdd)
                ? stopPropagation
                : () => setShowPlaylistSelector(true)
            }
          >
            <ArrowRightSVG />
            {t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.addToPlaylist)}
            {(isCanRemix && !isEmpty && !disabledBySelectedItemsForSFAdd) && (
            <PlaylistSelector
              setIsDropDownVisible={props.setIsActive}
              pageID={props.item?.id}
              lcID={props.item?.libraryComponent?.id}
              isWholePlaylistOperation={isWholePlaylistOperation}
              selectedItemsArray={allowedItems.forSF}
              isMultiActionPanel={props.isMultiActionPanel}
              refMenu={refMenu}
              show={showPlaylistSelector}
              hide={() => setShowPlaylistSelector(false)}
              upvCard={upvCard}
            />
            )}
          </OptionsWrapper>
        </>
      )}
      {/* {aiOn && (isWholePlaylistOperation || !!Object.keys(currentPage.selectedItems).length) && (
        <>
          <Divider />
          <OptionsWrapper onClick={summarizedHandler}>
            {t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.summarize)}
          </OptionsWrapper>
        </>
      )} */}
      {aiOn && (isWholePlaylistOperation || !!Object.keys(currentPage.selectedItems).length) && (
        <>
          <Divider />
          <OptionsWrapper onClick={AITaskHandler}>
            {t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.ai_send)}
          </OptionsWrapper>
        </>
      )}
      {isCanDelete && isWholePlaylistOperation && (
        <>
          <Divider />
          <OptionsWrapper
            isLast
            disabled={
              isViewer
              || (!itemOwner && isCoEdit)
              || ((isCoEdit || isLocked) && !itemOwner && !isOwner)
            }
            onClick={
              isViewer
              || (!itemOwner && isCoEdit)
              || ((isCoEdit || isLocked) && !itemOwner && !isOwner)
                ? stopPropagation
                : deleteLinkPageClickHandler
            }
          >
            {!isWholePlaylistOperation
              && t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.delete)}
            {isWholePlaylistOperation
              && t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.deletePlaylist)}
          </OptionsWrapper>
        </>
      )}
      {canUnsubscribe && isWholePlaylistOperation && (
        <>
          <Divider />
          <OptionsWrapper isLast onClick={unsubscribeHandler}>
            {t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.unsubscribe)}
          </OptionsWrapper>
        </>
      )}
      {(isCanRemoveFromChannel || isShowNotActiveRemoveFromChannel) && isWholePlaylistOperation && (
        <>
          <Divider />
          <OptionsWrapper
            disabled={isShowNotActiveRemoveFromChannel}
            isLast
            onClick={removeFromChannel}
          >
            {t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.removeFromChannel)}
          </OptionsWrapper>
        </>
      )}
    </ThreeDotsDropWrapperMaker>
  );
};

export default ThreeDotsDropDownMaker;
